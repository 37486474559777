// stores/user-store.js
import { defineStore } from 'pinia'
import UserService from '@/services/user.service'

export const useUserStore = defineStore('user', {
  state: () => ({
    id: null,
    token: null,
    adminToken: null,
    firstName: null,
    lastName: null,
    email: null,
    image: null,
    role_names: []
  }),
  getters: {
    getRoleNames: (state) => state.role_names,
    isAdmin: (state) => state.role_names.includes('admin'),
    isProfessional: (state) => state.role_names.includes('professional'),
    isBecomeUser: (state) => state.adminToken != null
  },
  actions: {
    setUserDetails(res) {
      if (res.data.token) this.$state.token = res.data.token
      this.$state.id = res.data.user.id
      this.$state.firstName = res.data.user.first_name
      this.$state.lastName = res.data.user.last_name
      this.$state.email = res.data.user.email
      this.$state.role_names = res.data.user.role_names
    },

    adminSetBecomeUserDetails(adminToken, res) {
      this.$state.adminToken = adminToken
      if (res.data.token) this.$state.token = res.data.token
      this.$state.id = res.data.user.id
      this.$state.firstName = res.data.user.first_name
      this.$state.lastName = res.data.user.last_name
      this.$state.email = res.data.user.email
      this.$state.role_names = res.data.user.role_names
    },

    adminStopBeingUser() {
      this.$state.token = this.$state.adminToken
      this.$state.adminToken = null
      return this.fetchUser()
    },

    async fetchUser() {
      let res = await UserService.getMe()
      this.setUserDetails(res)
    },

    async clearUser() {
      this.$state.id = null
      this.$state.token = null
      this.$state.adminToken = null
      this.$state.firstName = null
      this.$state.lastName = null
      this.$state.email = null
      this.$state.role_names = []
    }
  },
  persist: true
})
