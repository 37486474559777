<template>
  <div id="EditProfile" class="container max-w-4xl mx-auto pt-20 pb-20 px6">

    <div class="text-gray-900 text-3xl text-center">Add User</div>
    <div class="bg-innogreen w-full h-1 my-2"></div>

    <CropperModal
      v-if="showModal"
      :minAspectRatioProp="{width: 6, height: 8}"
      :maxAspectRatioProp="{width: 6, height: 8}"
      @croppedImageData="setCroppedImageData"
      @showModal="showModal = false"
    />

    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-full md:w-1/2 px-3">
        <TextInput 
            label="First Name"
            placeholder="Sam"
            v-model:input="firstName"
            inputType="text"
            :error="errors.first_name ? errors.first_name[0] : ''"
        />
      </div>
      <div class="w-full md:w-1/2 px-3">
        <TextInput 
            label="Last Name"
            placeholder="Chavez"
            v-model:input="lastName"
            inputType="text"
            :error="errors.last_name ? errors.last_name[0] : ''"
        />
      </div>
    </div>
    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-full md:w-1/2 px-3">
        <TextInput 
            label="Email"
            placeholder="samchavez@gmail.com"
            v-model:input="email"
            inputType="email"
            :error="errors.email ? errors.email[0] : ''"
        />
      </div>
      <div class="w-full md:w-1/2 px-3">
        <TextInput 
            label="Password"
            placeholder="iLovePi22a!"
            v-model:input="password"
            inputType="text"
            :error="errors.password ? errors.password[0] : ''"
        />
      </div>
    </div>

    <div class="flex flex-wrap mt-8 mb-6">
      <div class="w-full px-3">
        <SubmitFormButton 
            btnText="Save"
            @click="submit"
        />
      </div>
    </div>

  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue'
  // import { useRouter } from 'vue-router'
  import TextInput from '@/components/global/TextInput.vue'
  import SubmitFormButton from '@/components/global/SubmitFormButton.vue'
  import CropperModal from '@/components/global/CropperModal.vue'
  import UserService from '@/services/user.service.js'
  import { useToast } from 'vue-toastification'

  // const router = useRouter()
  const toast = useToast()

  let firstName = ref(null)
  let lastName = ref(null)
  let email = ref(null)
  let password = ref(null)
  let errors = ref([])

  onMounted(() => {
      init()
  })

  const init = () => {
    firstName.value = null
    lastName.value = null
    email.value = null
    password.value = null
    errors.value = []
  }

  const submit = async () => {
    errors.value = []

    let data = new FormData();
    data.append('first_name', firstName.value || '')
    data.append('last_name', lastName.value || '')
    data.append('email', email.value || '')
    data.append('password', password.value || '')
    
    try {
      let res = await UserService.adminAddUser(data)
      toast.success(res.data.message)
      init()
    } catch (err) {
      toast.error("There was an error saving the user.")
      errors.value = err.response.data.errors
    }
  }
</script>
