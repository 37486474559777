<template>
  <div id="AddUsdaIngredient" class="container max-w-4xl mx-auto pt-20 pb-20 px6">

    <div class="text-gray-900 text-3xl text-center">Nutrients<br><span class="text-base">with % Ranges for Daily Need vs Recipe Values that will be starter values when new Dietary Needs per Kg are created</span></div>
    <div class="bg-innogreen w-full h-1 my-2"></div>

    <div v-if="nutrients.length > 0">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                      <th scope="col" class="px-6 py-3">
                          Nutrient name
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Too Low Max
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Too High Min
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="(nutrient, index) in nutrients" :key="nutrient.id"
                    :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {{ nutrient.name }}
                      </th>
                      <td class="px-6 py-4">
                        <input type="text" class="w-20" v-model="nutrient.low_max" @keyup.enter="saveNutrient(nutrient)">
                      </td>
                      <td class="px-6 py-4">
                        <input type="text" class="w-20" v-model="nutrient.high_min" @keyup.enter="saveNutrient(nutrient)">
                      </td>
                  </tr>
                  <tr class="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Ca/P Ratio
                      </th>
                      <td class="px-6 py-4">
                        <input type="text" class="w-20" v-model="caPRatio.low_max" @keyup.enter="saveCaPRatio()">
                      </td>
                      <td class="px-6 py-4">
                        <input type="text" class="w-20" v-model="caPRatio.high_min" @keyup.enter="saveCaPRatio()">
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
    </div>

  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue'
  import nutrientService from '@/services/nutrient.service'
  import CaPRatioService from '@/services/caPRatio.service'
  import { useToast } from "vue-toastification"
  
  let nutrients = ref([])
  let caPRatio = ref({low_max: 50, high_min: 150})
  let errors = ref([])
  const toast = useToast()

  onMounted(async () => {
    await Promise.all([getNutrients(), getCaPRatio()])
  })

  const getNutrients = async () => {
    try {
      let res = await nutrientService.getNutrients()
      nutrients.value = res.data.nutrients
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }

  const getCaPRatio = async () => {
    try {
      let res = await CaPRatioService.getDefault()
      caPRatio.value = res.data.ca_p_ratio
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }

  const saveNutrient = async (nutrient) => {
    let data = new FormData();
    data.append('nutrient', JSON.stringify(nutrient))

    try {
      await nutrientService.updateNutrient(nutrient.id, data)
      toast.success(nutrient.name + " saved.")
    } catch (err) {
      if (err.response.data.errors && Array.isArray(err.response.data.errors)) errors.value = err.response.data.errors
      toast.error(err.response.data.message)
    }
  }

  const saveCaPRatio = async () => {
    try {
      await CaPRatioService.update(caPRatio.value.id, caPRatio.value.low_max, caPRatio.value.high_min)
      toast.success("Ca/P Ratio saved.")
    } catch (err) {
      if (err.response.data.errors && Array.isArray(err.response.data.errors)) errors.value = err.response.data.errors
      toast.error(err.response.data.message)
    }
  }
</script>
