<template>
  <div id="Register">
    <TopNavigation />

    <div class="flex-1 h-full max-w-4xl mx-auto bg-white rounded-lg shadow-xl mt-20 mb-20">
      <div class="flex flex-col md:flex-row">
        <div class="h-32 md:h-auto md:w-1/2">
          <img class="object-cover w-full h-full" src="@/assets/dog_carrot.jpg"
            alt="ChefPaw with Carrot" />
        </div>
        <div class="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
          <div class="w-full">
            <h1 class="mb-4 text-2xl font-bold text-center text-gray-700">
              Login
            </h1>
            <div class="mt-4">
              <TextInput 
                  label="Email"
                  placeholder="sam.doe@m.com"
                  v-model:input="email"
                  inputType="text"
                  :error="errors.email ? errors.email[0] : ''"
                  @keyup.enter="login"
              />
            </div>
            <div class="pt-4">
              <TextInput 
                  label="Password"
                  placeholder="password123?"
                  v-model:input="password"
                  inputType="password"
                  :error="errors.password ? errors.password[0] : ''"
                  @keyup.enter="login"
              />
            </div>
            <button class="block w-full mt-6 px-4 py-3 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-innoorange border border-transparent rounded-lg active:bg-innoorange hover:bg-innoorange-hover focus:outline-none focus:shadow-outline-innoorange"
              type="submit"
              @click="login" :disabled="isLoading">
              <svg v-if="isLoading" class="w-5 h-5 animate-spin ml-auto mr-auto" fill="none"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      fill="currentColor"></path>
              </svg>
              <span v-if="!isLoading">Login</span>
            </button>

            <div class="mt-4 text-center">
              <p class="text-sm">Forgot password?
                <a href="javascript:void(0)" class="text-blue-600 underline" @click="passwordReset()">Reset</a>
              </p>
            </div>

            <div class="mt-4 text-center">
              <p class="text-sm">
                *New ChefPaw owners - Please check the email account you provided when making your ChefPaw device purchase for a message on how to access your account. If you don't see a message then you may try to <a href="javascript:void(0)" class="text-blue-600 underline" @click="passwordReset()">reset</a> your password using that same email address or <a href="https://chefpaw-help-center.gorgias.help/en-US" class="text-blue-600 underline" target="_blank">click here</a> to get information on how to contact us by email, chat or phone.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
  import { ref } from 'vue'
  import { useRouter } from 'vue-router'
  import UserService from '../services/user.service'
  import { useUserStore } from '../store/user-store'
  import TextInput from '../components/global/TextInput.vue'
  import TopNavigation from '../components/structure/TopNavigation.vue'
  import Swal from 'sweetalert2'

  const router = useRouter()
  const userStore = useUserStore()

  let errors = ref([])
  let email = ref(null)
  let password = ref(null)
  let isLoading = ref(false)

  const login = async () => {
    errors.value = []

    try {
      isLoading.value = true
      let res = await UserService.login({
        email: email.value,
        password: password.value
      })

      userStore.setUserDetails(res)
      isLoading.value = false
      router.push('/account/home')
    } catch (err) {
      isLoading.value = false
      if (err.response.data.errors) errors.value = err.response.data.errors
      Swal.fire({
        title: 'Error',
        text: err.response.data.message,
        icon: 'error',
        confirmButtonColor: '#F28705'
      })
    }
  }

  const passwordReset = async () => {
    Swal.fire({
      title: 'Request Password Reset',
      text: "Provide your email address and we'll email you a link you can use to reset your password.",
      input: 'email',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Submit',
      confirmButtonColor: '#F28705',
      showLoaderOnConfirm: true,
      preConfirm: (email) => {
        return UserService.requestPasswordReset(email)
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: `Request Submitted`,
          text: 'If an account with that email address exists then a password reset link is being sent to it.',
          confirmButtonColor: '#F28705'
        })
      }
    })
  }
</script>
