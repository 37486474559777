<template>
  <div id="ListPets" class="container max-w-4xl mx-auto pt-10 pb-20 px6">

    <div class="text-gray-900 text-3xl text-center">My Pets</div>
    <div class="bg-innogreen w-full h-1 my-2"></div>
    
    <div v-if="pets.length > 0">
      <PetCard v-for="pet in pets" :pet="pet" :key="pet.id" />
    </div>

    <div class="grid place-items-center">
      <div class="p-4 w-11/12 md:w-2/3">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="addPet()">
          Add Pet
        </button>
      </div>
    </div>

    <div class="container max-w-4xl mx-auto text-xs text-gray-700">
        <div class="text-center"><strong>Disclaimer for Ingredient Nutrition:</strong></div>
        <p>
          The default nutrient values of ingredients are averaged from a variety of sources including the USDA food database, FDA and NIH published Papers. Actual nutritional yields from any ingredient will vary based on the ingredient source. These values are meant as a general guide and Innovet Pet Products cannot guarantee the nutritional yield of any ingredient. Ingredient values can easily be customized by adding a new ingredient.
        </p>
    </div>

  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue'
  import { useRouter } from 'vue-router'
  import PetService from '../../services/pet.service'
  import PetCard from '@/components/PetCard.vue'

  const router = useRouter()

  let pets = ref([])

  onMounted(async () => {
      await getPets()
  })

  const getPets = async () => {
    try {
      let res = await PetService.listPets()
      pets.value = res.data.pets
    } catch (err) {
      // error
    }
  }

  const addPet = () => {
    router.push({ name: 'AddPet' })
  }
</script>
