import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@/store/user-store'
import HomeView from '../views/HomeView.vue'
import RegisterView from '../views/RegisterView.vue'
import LoginView from '../views/LoginView.vue'
import ResetPasswordView from '../views/ResetPasswordView.vue'
import AccountView from '../views/AccountView.vue'
import AccountHome from '../views/account/AccountHome.vue'
import ProfileSection from '../views/account/ProfileSection.vue'
import EditProfile from '../views/account/EditProfile.vue'
import AddUsdaIngredient from '../views/account/AddUsdaIngredient.vue'
import CreateRecipe from '../views/account/CreateRecipe.vue'
import EditRecipe from '../views/account/EditRecipe.vue'
import ShowRecipe from '../views/account/ShowRecipe.vue'
import CreateMealPlan from '../views/account/CreateMealPlan.vue'
import EditMealPlan from '../views/account/EditMealPlan.vue'
import RecipeNutrition from '../views/account/RecipeNutrition.vue'
import MealPlanNutrition from '../views/account/MealPlanNutrition.vue'
import IngredientNutrition from '../views/account/IngredientNutrition.vue'
import ListIngredients from '../views/account/ListIngredients.vue'
import ListRecipes from '../views/account/ListRecipes.vue'
import ListPets from '../views/account/ListPets.vue'
import AddPet from '../views/account/AddPet.vue'
import EditPet from '../views/account/EditPet.vue'
import PetDailyNutrition from '../views/account/PetDailyNutrition.vue'
import PetRecipeNutrition from '../views/account/PetRecipeNutrition.vue'
import ListMealPlans from '../views/account/ListMealPlans.vue'
import PetMealPlanNutrition from '../views/account/PetMealPlanNutrition.vue'
import EditIngredient from '../views/admin/EditIngredient.vue'
import AdminHome from '../views/admin/AdminHome.vue'
import ListDietaryNeedsPerKg from '../views/admin/ListDietaryNeedsPerKg.vue'
import EditDietaryNeedsPerKg from '../views/admin/EditDietaryNeedsPerKg.vue'
import EditDietaryNeedsPerKgPro from '../views/professional/EditDietaryNeedsPerKg.vue'
import ShowDietaryNeedsPerKgView from '../views/admin/ShowDietaryNeedsPerKg.vue'
import AddDietaryNeedsPerKgView from '../views/admin/AddDietaryNeedsPerKg.vue'
import ListMyRecipesView from '../views/account/ListMyRecipes.vue'
import ListRecipesSharedWithMeView from '../views/account/ListRecipesSharedWithMe.vue'
import ListInspiredRecipesView from '../views/account/ListInspiredRecipes.vue'
import ListSpecieInspiredRecipesView from '../views/account/ListSpecieInspiredRecipes.vue'
import ListSpecieMyRecipesView from '../views/account/ListSpecieMyRecipes.vue'
import SuggestedRecipesView from '../views/account/SuggestedRecipes.vue'
import RecipesLibraryPageView from '../views/account/RecipeLibraryPage.vue'
import BatchRecipesDetailView from '../views/account/BatchRecipesDetail.vue'
import RecipeInstruction from '../views/account/RecipeInstruction.vue'
import RecipeSteps from '../views/account/RecipeSteps.vue'
import RecipesMainPageView from '../views/account/RecipesMain.vue'
import ListNutrientsView from '../views/admin/ListNutrients.vue'
import AdminAddUserView from '../views/admin/AddUser.vue'
import MakeABatchView from '../views/account/MakeABatch.vue'
import DogRecipesHomeView from '../views/account/DogRecipesHomeView.vue'
import SpecieRecipesCategoriesView from '../views/account/SpecieRecipesCategoriesView.vue'
import SpecieRecipesCategoryView from '../views/account/SpecieRecipesCategoryView.vue'
import DogRecipesSuggestedView from '../views/account/DogRecipesSuggestedView.vue'
import DogRecipesSpecialtyView from '../views/account/DogRecipesSpecialtyView.vue'
import ListDogRecipesCategoryView from '../views/account/ListDogRecipesCategoryView.vue'
import ListSharedRecipesView from '../views/admin/ListSharedRecipes.vue'
import VeterinarianFormulatedRecipesView from '../views/account/VeterinarianFormulatedRecipesView.vue'
import ShowRecipeGroceryListView from '../views/account/ShowRecipeGroceryList.vue'
import ListUsersView from '../views/admin/ListUsers.vue'
import ProfessionalHomeView from '../views/professional/ProfessionalHome.vue'
import ListUserAuthAttemptsView from '../views/admin/ListUserAuthAttempts.vue'
import ShowMealPlanGroceryListView from '../views/account/ShowMealPlanGroceryList.vue'
import RecipesHomeView from '../views/account/RecipesHomeView.vue'
import SpeciesRecipesHomeView from '../views/account/SpeciesRecipesHomeView.vue'
import RecipeAdvancedEditForPetView from '../views/account/RecipeAdvancedEditForPetView.vue'

const routes = [
  {
    path: '/',
    beforeEnter: (to, from, next) => {
      useUserStore().id ? next('/account/home') : next()
    },
    name: 'home',
    component: HomeView,
    meta: {
      title: 'ChefPaw Portal',
    },
  },
  {
    path: '/register',
    beforeEnter: (to, from, next) => {
      useUserStore().id ? next('/account/home') : next()
    },
    name: 'register',
    component: RegisterView,
    meta: {
      title: 'ChefPaw Portal',
    }
  },
  {
    path: '/login',
    beforeEnter: (to, from, next) => {
      useUserStore().id ? next('/account/home') : next()
    },
    name: 'login',
    component: LoginView,
    meta: {
      title: 'ChefPaw Portal',
    }
  },
  {
    path: '/reset-password',
    beforeEnter: (to, from, next) => {
      useUserStore().id ? next('/account/home') : next()
    },
    name: 'ResetPassword',
    component: ResetPasswordView,
    meta: {
      title: 'ChefPaw Portal',
    }
  },
  {
    path: '/account',
    beforeEnter: (to, from, next) => {
      useUserStore().id ? next() : next('/login')
    },
    component: AccountView,
    meta: {
      title: 'ChefPaw Portal',
    },
    children: [
      {
        path: 'home',
        name: 'AccountHome',
        component: AccountHome
      },
      {
        path: 'profile',
        name: 'ProfileSection',
        component: ProfileSection
      },
      {
        path: 'edit-profile',
        name: 'EditProfile',
        component: EditProfile
      },
      {
        path: 'add-usda-ingredient',
        name: 'AddUsdaIngredient',
        component: AddUsdaIngredient
      },
      {
        path: 'create-recipe',
        name: 'CreateRecipe',
        component: CreateRecipe
      },
      {
        path: 'edit-recipe/:id',
        name: 'EditRecipe',
        component: EditRecipe
      },
      {
        path: 'recipes-home',
        name: 'RecipesHome',
        component: RecipesHomeView
      },
      {
        path: 'species/:slug/inspired-recipes',
        name: 'ListSpecieInspiredRecipes',
        component: ListSpecieInspiredRecipesView
      },
      {
        path: 'species/:slug/my-recipes',
        name: 'ListSpecieMyRecipes',
        component: ListSpecieMyRecipesView
      },
      {
        path: 'species/:slug/recipes-home',
        name: 'SpeciesRecipesHome',
        component: SpeciesRecipesHomeView
      },
      {
        path: 'species/:slug/recipes-categories/:tag',
        name: 'SpecieRecipesCategory',
        component: SpecieRecipesCategoryView
      },
      {
        path: 'species/:slug/recipes-categories',
        name: 'SpecieRecipesCategories',
        component: SpecieRecipesCategoriesView
      },
      {
        path: 'dog-recipes-home',
        name: 'DogRecipesHome',
        component: DogRecipesHomeView
      },
      {
        path: 'dog-recipes-suggested',
        name: 'DogRecipesSuggested',
        component: DogRecipesSuggestedView
      },
      {
        path: 'dog-recipes-specialty',
        name: 'DogRecipesSpecialty',
        component: DogRecipesSpecialtyView
      },
      {
        path: 'recipe/:id',
        name: 'ShowRecipe',
        component: ShowRecipe
      },
      {
        path: 'recipe-grocery-list/:id',
        name: 'RecipeGroceryList',
        component: ShowRecipeGroceryListView
      },
      {
        path: 'meal-plan-grocery-list/:id',
        name: 'MealPlanGroceryList',
        component: ShowMealPlanGroceryListView
      },
      {
        path: 'create-meal-plan',
        name: 'CreateMealPlan',
        component: CreateMealPlan
      },
      {
        path: 'edit-meal-plan/:id',
        name: 'EditMealPlan',
        component: EditMealPlan
      },
      {
        path: 'recipe-nutrition/:id',
        name: 'RecipeNutrition',
        component: RecipeNutrition
      },
      {
        path: 'meal-plan-nutrition/:id',
        name: 'MealPlanNutrition',
        component: MealPlanNutrition
      },
      {
        path: 'ingredients',
        name: 'ListIngredients',
        component: ListIngredients
      },
      {
        path: 'recipes',
        name: 'ListRecipes',
        component: ListRecipes
      },
      {
        path: 'dog-recipes-category/:tagSlug',
        name: 'ListDogrecipesCategory',
        component: ListDogRecipesCategoryView
      },
      {
        path: 'make-a-batch',
        name: 'MakeABatch',
        component: MakeABatchView
      },
      {
        path: 'ingredient-nutrition/:id',
        name: 'IngredientNutrition',
        component: IngredientNutrition
      },
      {
        path: 'pets',
        name: 'ListPets',
        component: ListPets
      },
      {
        path: 'add-pet',
        name: 'AddPet',
        component: AddPet
      },
      {
        path: 'pets/:id/edit',
        name: 'EditPet',
        component: EditPet
      },
      {
        path: 'pets/:id/daily-nutrition',
        name: 'PetDailyNutrition',
        component: PetDailyNutrition
      },
      {
        path: 'pets/:petId/recipe-nutrition/:recipeId',
        name: 'PetRecipeNutrition',
        component: PetRecipeNutrition
      },
      {
        path: 'meal-plans',
        name: 'ListMealPlans',
        component: ListMealPlans
      },
      {
        path: 'pets/:petId/meal-plan-nutrition/:mealPlanId',
        name: 'PetMealPlanNutrition',
        component: PetMealPlanNutrition
      },
      {
        path: 'my-recipes',
        name: 'MyRecipes',
        component: ListMyRecipesView
      },
      {
        path: 'recipes-shared-with-me',
        name: 'ListRecipesSharedWithMe',
        component: ListRecipesSharedWithMeView
      },
      {
        path: 'inspired-recipes',
        name: 'InspiredRecipes',
        component: ListInspiredRecipesView,
      },
      {
        path: 'suggested-recipes',
        name: 'SuggestedRecipes',
        component: SuggestedRecipesView,
      },
      {
        path: 'recipes-library',
        name: 'RecipesLibrary',
        component: RecipesLibraryPageView,
      },
      {
        path: 'recipes-veterinarian-formulated',
        name: 'VeterinarianFormulatedRecipes',
        component: VeterinarianFormulatedRecipesView,
      },
      {
        path: 'recipes-batch/:id',
        name: 'RecipesBatchSummary',
        component: BatchRecipesDetailView,
      },
      {
        path: 'recipe-instruction/:id',
        name: 'RecipeInstruction',
        component: RecipeInstruction,
      },
      {
        path: 'recipes/:id/recipe-steps/:step',
        name: 'RecipeSteps',
        component: RecipeSteps,
      },
      {
        path: 'recipes-main',
        name: 'RecipesMain',
        component: RecipesMainPageView,
      },
      {
        path: 'recipes/:recipeId/advanced-edit-for-pet/:petId',
        name: 'RecipeAdvancedEditForPet',
        component: RecipeAdvancedEditForPetView,
      }
    ]
  },
  {
    path: '/admin',
    component: AccountView,
    children: [
      {
        path: 'home',
        beforeEnter: (to, from, next) => {
          useUserStore().id && useUserStore().isAdmin ? next() : next('/login')
        },
        name: 'AdminHome',
        component: AdminHome
      },
      {
        path: 'edit-ingredient/:id',
        beforeEnter: (to, from, next) => {
          useUserStore().id && useUserStore().isAdmin ? next() : next('/login')
        },
        name: 'EditIngredient',
        component: EditIngredient
      },
      {
        path: 'dietary-needs-per-kg',
        beforeEnter: (to, from, next) => {
          useUserStore().id && (useUserStore().isAdmin || useUserStore().isProfessional) ? next() : next('/login')
        },
        name: 'ListDietaryNeedsPerKg',
        component: ListDietaryNeedsPerKg
      },
      {
        path: 'dietaryneeds-per-kg/:id',
        beforeEnter: (to, from, next) => {
          useUserStore().id && (useUserStore().isAdmin || useUserStore().isProfessional) ? next() : next('/login')
        },
        name: 'ShowDietaryNeedsPerKg',
        component: ShowDietaryNeedsPerKgView
      },
      {
        path: 'add-dietaryneeds-per-kg',
        beforeEnter: (to, from, next) => {
          useUserStore().id && (useUserStore().isAdmin || useUserStore().isProfessional) ? next() : next('/login')
        },
        name: 'AddDietaryNeedsPerKg',
        component: AddDietaryNeedsPerKgView
      },
      {
        path: 'edit-dietaryneeds-per-kg/:id',
        beforeEnter: (to, from, next) => {
          useUserStore().id && (useUserStore().isAdmin || useUserStore().isProfessional) ? next() : next('/login')
        },
        name: 'EditDietaryNeedsPerKg',
        component: EditDietaryNeedsPerKg
      },
      {
        path: 'nutrients',
        beforeEnter: (to, from, next) => {
          useUserStore().id && useUserStore().isAdmin ? next() : next('/login')
        },
        name: 'ListNutrients',
        component: ListNutrientsView
      },
      {
        path: 'users/add',
        beforeEnter: (to, from, next) => {
          useUserStore().id && useUserStore().isAdmin ? next() : next('/login')
        },
        name: 'AdminAddUser',
        component: AdminAddUserView
      },
      {
        path: 'shared-recipes',
        beforeEnter: (to, from, next) => {
          useUserStore().id && useUserStore().isAdmin ? next() : next('/login')
        },
        name: 'SharedRecipes',
        component: ListSharedRecipesView
      },
      {
        path: 'users',
        beforeEnter: (to, from, next) => {
          useUserStore().id && useUserStore().isAdmin ? next() : next('/login')
        },
        name: 'ListUsers',
        component: ListUsersView
      },
      {
        path: 'user-auth-attempts/:id',
        beforeEnter: (to, from, next) => {
          useUserStore().id && useUserStore().isAdmin ? next() : next('/login')
        },
        name: 'ListUserAuthAttempts',
        component: ListUserAuthAttemptsView
      }
    ]
  },
  {
    path: '/professional',
    beforeEnter: (to, from, next) => {
      useUserStore().id && useUserStore().isProfessional ? next() : next('/login')
    },
    component: AccountView,
    children: [
      {
        path: 'home',
        name: 'ProfessionalHome',
        component: ProfessionalHomeView
      },
      {
        path: 'edit-dietaryneeds-per-kg/:id',
        beforeEnter: (to, from, next) => {
          useUserStore().id && (useUserStore().isProfessional) ? next() : next('/login')
        },
        name: 'EditDietaryNeedsPerKgPro',
        component: EditDietaryNeedsPerKgPro
      },
    ]
  }
]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
