<template>
  <div id="AddUsdaIngredient" class="container max-w-4xl mx-auto pt-20 pb-20 px6">

    <div class="text-gray-900 text-3xl text-center">Recipe Nutrition:<br>{{ recipe.name }}</div>
    <div class="bg-innogreen w-full h-1 my-2"></div>

    <div v-if="recipe.ingredients.length > 0" class="pt-5 pb-5">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg max-h-[700px]">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="sticky top-0 text-xs text-white uppercase bg-innogreen">
                  <tr>
                    <th scope="col" class="px-4 py-3">
                        Nutrient / Data Point
                    </th>  
                    <th scope="col" class="px-4 py-3">
                        {{ recipe.name }} Recipe
                    </th>
                    <th scope="col" class="px-4 py-3" v-for="ingredient in recipe.ingredients" :key="ingredientHeaderKey(ingredient)">
                        {{ ingredient.name }}
                    </th>
                  </tr>
              </thead>
              <tbody>
                <tr class="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                  <th scope="row" class="px-4 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    Total Grams
                  </th>
                  <td scope="row" class="px-4 py-4">
                    {{ sumIngredientsGrams() }}
                  </td>
                  <td class="px-4 py-4" v-for="ingredient in recipe.ingredients" :key="ingredientTotalKey(ingredient)">
                    {{ ingredient.amount.total_g }}
                  </td>
                </tr>  
                <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <th scope="row" class="px-4 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">% of recipe</th>
                  <td scope="row" class="px-4 py-4">100%</td>
                  <td class="px-4 py-4" v-for="ingredient in recipe.ingredients" :key="ingredientPercentKey(ingredient)">
                    {{ ingredient.amount.percent_of_recipe }}%
                  </td>
                </tr>
                <tr v-for="(nutrient, index) in recipe.nutrition_breakdown.nutrients" :key="recipeNutrientKey(recipe, nutrient)"
                  :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                    <th scope="row" class="md:hidden px-4 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      <Popper :content="nutrient.name" :disabled="nutrient.name.length < nutrientMaxChars">
                        {{ $filters.strLimit(nutrient.name, nutrientMaxChars) }}
                      </Popper>
                    </th>
                    <th scope="row" class="hidden md:table-cell px-4 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {{ nutrient.name }}
                    </th>
                    <td class="px-4 py-4">
                      {{ nutrient.amount.qty }}{{ nutrient.amount.unit_type }}
                    </td>
                    <td class="px-4 py-4" v-for="ingredient in recipe.ingredients" :key="ingredientBodyKey(ingredient)">
                      {{ ingredientNutrientAmount(ingredient, index) }}{{ ingredient.nutrition_breakdown.nutrients[index].amount.unit_type }}
                    </td>
                </tr>
              </tbody>
          </table>
      </div>
    </div>


  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue'
  import { useRoute } from 'vue-router'
  import RecipeService from '../../services/recipe.service'
  import Popper from "vue3-popper"
  import _ from 'lodash'

  const route = useRoute()

  let recipe = ref({ingredients: []})
  let errors = ref([])
  let nutrientMaxChars = ref(12)

  onMounted(async () => {
      await getRecipe()
  })

  const getRecipe = async () => {
    try {
      let res = await RecipeService.getRecipeWithNutritionAndHiddenNutrients(route.params.id)
      recipe.value = res.data.recipe
      console.log(res.data)
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }

  const ingredientNutrientAmount = (ingredient, index) => {
    let baseline_amount = ingredient.nutrition_breakdown.total_qty
    if (baseline_amount == 0) return 0
    let recipe_amount = ingredient.amount.total_g
    let multiplier = recipe_amount / baseline_amount
    let amount = 0
    try {
      amount = ingredient.nutrition_breakdown.nutrients[index].amount.qty * multiplier
    } catch (error) {
      console.log('trying to get nutrient ingredient', ingredient, index)
      console.log('error')
    }
    return Math.round(amount * 100) / 100
  }

  const ingredientHeaderKey = (ingredient)  => {
    return 'head-'+ingredient.id
  }

  const ingredientPercentKey = (ingredient)  => {
    return 'percent-'+ingredient.id
  }

  const ingredientTotalKey = (ingredient)  => {
    return 'total-'+ingredient.id
  }
  
  const ingredientBodyKey = (ingredient)  => {
    return 'body-'+ingredient.id
  }

  const recipeNutrientKey = (recipe, nutrient)  => {
    return recipe.id+'-'+nutrient.id
  }

  const sumIngredientsGrams = () => {
    let sum = 0
    _.each(recipe.value.ingredients, function (ingredient) {
      sum += parseFloat(ingredient.amount.total_g)
    })
    return sum
  }
</script>
