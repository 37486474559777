<template>
  <div id="AddUsdaIngredient" class="container max-w-4xl mx-auto pt-20 pb-20 px6">

    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-1/4">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="goBack()">
          Back
        </button>
      </div>
    </div>

    <div class="text-gray-900 text-3xl text-center">Veterinarian Formulated Recipes</div>
    <div class="bg-innogreen w-full h-1 my-2"></div>

    <h2 class="text-2xl mt-4">Dr. Jessica Fusch</h2>
    <div class="text-xl">Our Certified Veterinary Food Technician</div>
    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-full pt-2 pb-4 md:hidden md:w-1/4 self-center flex justify-center items-center">
        <img src="@/assets/fusch_profile_picture_300x300_d5.png" alt="Dr. Jessica Fusch" />
      </div>
      <div class="w-full md:w-3/4 text-left pr-4">
        We're excited to introduce Dr. Jessica Fusch, our ChefPaw CVFT. A graduate of the University of Florida with certifications in acupuncture and food therapy, Dr. Fusch brings a wealth of experience in pet nutrition and holistic animal care. Her lifelong passion for animal wellbeing shines through her YouTube channel, Farmer's Market Fido, where she shares valuable insights on feeding pets whole, fresh foods. At ChefPaw, Dr. Fusch will be offering personalized nutritional consultations, especially for pets with food allergies. Her expertise is a significant addition to our commitment to enhancing pet health.
        <i>*Please note that recipes needed for specific medical conditions may require a consult with a CVFT that also is a board-certified Veterinary Nutritionist.</i>
      </div>
      <div class="w-full pt-4 hidden md:flex md:w-1/4 self-center md:pl-4 justify-center items-center">
        <img src="@/assets/fusch_profile_picture_300x300_d5.png" alt="Dr. Jessica Fusch" />
      </div>
    </div>
    <div class="w-48 mt-4 mx-auto">
      <a href="https://calendly.com/keyvetcare" target="_blank">
        <button
          class="float-right
                  text-gray-900
                  hover:text-white
                  py-2
                  px-4
                  rounded
                  w-full
                  text-gray-100
                  text-center
                  text-lg mt-2
                  bg-innoorange
                  hover:bg-innoorange-hover">
          Book Now
        </button>
      </a>
    </div>
  </div>
</template>

<script setup>

  import { onMounted } from 'vue'
  import { useRouter } from 'vue-router'

  const router = useRouter()

  onMounted(async () => {
    // nothing
  })

  const goBack = () => {
    router.go(-1)
  }
  
</script>
