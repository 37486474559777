<template>
  <div id="AddUsdaIngredient" class="container max-w-4xl mx-auto pt-20 pb-20 px6">

    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-1/4">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="goMealPlans()">
          Done, Back to Meal Plans
        </button>
      </div>
    </div>

    <div class="text-gray-900 text-3xl text-center">Meal Plan: {{ mealPlan.name }}</div>
    <div class="bg-innogreen w-full h-1 my-2"></div>

    <div class="text-gray-900 text-xl text-center">Add recipes to or remove recipes from {{ mealPlan.name }}</div>

    <div v-if="mealPlan.recipes.length > 0" class="pt-5 pb-5">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg max-h-[700px]">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="sticky top-0 text-xs text-white uppercase bg-innogreen">
                  <tr>
                      <th scope="col" class="px-6 py-6">
                          Recipe Name
                      </th>
                      <th scope="col" class="px-6 py-6">
                          Action
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="(recipe, index) in mealPlan.recipes" :key="mealPlanRecipeKey(mealPlan, recipe)"
                    :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {{ recipe.name }}
                      </th>
                      <td class="px-6 py-4">
                        <div v-if="!recipe.loading">
                          <a @click="removeRecipeAtIndex(index)" href="javascript:void(0)" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Remove</a>
                        </div>
                        <div v-if="recipe.loading" class="spinner-border animate-spin inline-block w-4 h-4 rounded-full" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
    </div>
    
    <div class="pt-5 pb-5">
      <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
      <div class="relative">
          <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
          </div>
          <input v-model="query" @keyup.enter="searchRecipes()" type="text" class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-innoorange focus:border-innoorange dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-innoorange dark:focus:border-innoorange" placeholder="Search & Add ChefPaw Recipes..." required>
          <button :disabled="searching" @click="searchRecipes()" type="button" class="text-white absolute right-2.5 bottom-2.5 bg-innoorange hover:bg-innoorange-hover focus:ring-4 focus:outline-none focus:ring-innoorange font-medium rounded-lg text-sm px-4 py-2 dark:bg-innoorange dark:hover:bg-innoorange-hover dark:focus:ring-innoorange">
            <svg v-if="searching" class="w-5 h-5 animate-spin ml-auto mr-auto" fill="none"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    fill="currentColor"></path>
            </svg>
            <span v-if="!searching">
              Search
            </span>
          </button>
      </div>
    </div>

    <div v-if="recipes.length > 0">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg max-h-[700px]">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="sticky top-0 text-xs text-white uppercase bg-innogreen">
                  <tr>
                      <th scope="col" class="px-6 py-6">
                          Recipe name
                      </th>
                      <th scope="col" class="px-6 py-6">
                          Action
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="(recipe, index) in recipes" :key="recipe.id"
                    :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {{ recipe.name }}
                      </th>
                      <td class="px-6 py-4">
                        <div v-if="!recipe.loading">
                          <a v-if="!recipe.added" @click="addRecipe(recipe)" href="javascript:void(0)" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Add</a>
                          <span v-if="recipe.added" class="text-green">Added</span>
                        </div>
                        <div v-if="recipe.loading" class="spinner-border animate-spin inline-block w-4 h-4 rounded-full" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
    </div>

  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import RecipeService from '@/services/recipe.service.js'
  import MealPlanService from '@/services/mealPlan.service.js'
  import { useToast } from "vue-toastification"
  import _ from 'lodash'

  const route = useRoute()
  const router = useRouter()
  const toast = useToast()

  let searching = ref(false)
  let mealPlan = ref({recipes: []})
  let query = ref(null)
  let recipes = ref([])
  let errors = ref([])

  onMounted(async () => {
      await getPlan()
  })

  const getPlan = async () => {
    try {
      let res = await MealPlanService.getMealPlan(route.params.id)
      mealPlan.value = res.data.meal_plan
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }

  const searchRecipes = async () => {
    searching.value = true
    try {
      let res = await RecipeService.searchRecipes(query.value)
      console.log(res.data)
      recipes.value = res.data.recipes
      searching.value = false
    } catch (err) {
      errors.value = err.response.data.errors
      searching.value = false
    }
  }

  const addRecipe = async (recipe) => {

    if (_.find(mealPlan.value.recipes, {id: recipe.id})) {
      toast.warning(recipe.name + " recipe already in meal plan.")
      return
    }

    let data = new FormData();
    data.append('recipes', JSON.stringify([{id: recipe.id}]))

    try {
      let res = await MealPlanService.addRecipes(mealPlan.value.id, data)
      mealPlan.value = res.data.meal_plan
      toast.success(recipe.name + " recipe added.")
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }

  const editMealPlan = async (removedRecipe = null) => {
    let data = new FormData();
    data.append('meal_plan', JSON.stringify(mealPlan.value))

    try {
      let res = await MealPlanService.updateMealPlan(mealPlan.value.id, data)
      mealPlan.value = res.data.meal_plan
      if (removedRecipe) {
        toast.success(removedRecipe.name + " recipe removed.")
      }
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }

  const removeRecipeAtIndex = async (index) => {
    let removedRecipe = mealPlan.value.recipes[index]
    mealPlan.value.recipes.splice(index, 1)
    editMealPlan(removedRecipe)
  }

  const mealPlanRecipeKey = (mealPlan, recipe)  => {
    return mealPlan.id+'-'+recipe.id
  }

  const goMealPlans = () => {
    router.push({ name: 'ListMealPlans' })
  }
</script>
